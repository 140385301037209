export default {
  buttons: {
    showStateCodes: 'Show State Codes',
    samplePeriodicCostExcelDownload: 'Sample Periodic Additional Cost List',
    resetFilters: 'RESET FILTERS',
    merge: 'INVITE',
    clearFilter: 'CLEAR FILTERS',
    planSelecteds: 'UPDATE SELECTED DATA',
    reset: 'Reset',
    back: 'BACK',
    backNavigate: 'Back',
    next: 'Next',
    complete: '{value} Complete',
    getAnOffer: 'Get An Offer Right Now',
    addPackage: 'Add Package',
    addVolume: 'Add Dimensions',
    addBox: 'Add Package',
    changeTheSearch: 'Change The Search',
    createOrder: 'Create An Order',
    demandPriceFromSupplier: 'Request An Special Offer From Supplier',
    demandPrice: 'Request A Price',
    searchAgain: 'Search Again',
    viewTheResults: 'View The Results',
    offerPrice: 'Offer A Price',
    updateOffer: 'Update The Offer',
    lost: 'Lost',
    expired: 'Expired',
    search: 'Search',
    rate: 'Rate The Supplier',
    giveCustomerAPrice: 'Give a Price',
    lumpSum: 'Pay For Selected({value} Payments) Ones',
    lumpSumMobile: 'Pay',
    lumpSumSupplier: 'Update the Selected({value} Payments) Payments as Paid',
    lumpSumSupplierMobile: 'Update the Selected Payments as Paid',
    completePayment: 'Complete The Payment',
    resetDates: 'Reset The Dates',
    deleteSelected: 'DELETE SELECTED',
    changeExcelFile: 'Change The Excel File',
    goToDetails: 'View Details',
    samplePriceExcelDownload: 'Sample Price List',
    sampleRegionExcelDownload: 'Sample Region List',
    sampleOutOfAreaExcelDownload: 'Sample Regions Exceptions List',
    blockUser: 'Block User',
    activateUser: 'Activate User',
    viewCompanyDetail: 'View Company Detail',
    refreshOrders: 'Refresh Orders',
    remainingRefreshCount: 'Remaining Refresh Count',
    viewPackageDetails: 'Package Details',
    removeUserFromBlacklist: 'Remove User From Blacklist',
    unbanUser: 'Remove Block',
    banUser: 'Block The User',
    approve: 'APPROVE',
    cancelOrder: 'Cancel Order',
    sendAMessage: 'Send A Message',
    saveDraft: 'SAVE AS DRAFT',
    selectOnMap: 'Select On Map',
    add: 'Add',
    update: 'Update',
    login: 'Login',
    smsRefresh: 'SEND SMS VERIFICATION CODE AGAIN',
    register: 'Register',
    registerNow: 'Register Now',
    forgotPass: 'Forgot Password',
    close: 'Close',
    select: 'SELECT',
    open: 'Open',
    orderDetail: 'Order Detail',
    offerDetail: 'Offer Detail',
    sendMessage: 'Send Message',
    resume: 'RESUME',
    assign: 'Assign',
    send: 'SEND',
    sendCode: 'Send A Code',
    sendCodeAgain: 'Resend The Code',
    updatePhone: 'UPDATE TELEPHONE NUMBER',
    cancel: 'Cancel',
    profile: 'Profile',
    logout: 'Logout',
    yes: 'YES',
    no: 'NO',
    ok: 'OK',
    submit: 'Submit',
    excelExport: 'EXCEL EXPORT',
    download: 'DOWNLOAD',
    clear: 'CLEAR',
    save: 'SAVE',
    saveLower: 'Save',
    isAlreadySended: 'Sended',
    createFile: 'Create File',
    inReviewOrder: 'Review Order',
    publish: 'Publish',
    saveAsDraft: 'Save as Draft',
    uploadNew: 'UPLOAD NEW',
    uploadfromLibrary: 'ADD FROM LIBRARY',
    addNew: 'ADD NEW',
    addNewGroup: 'ADD NEW GROUP',
    makeAdmin: 'MAKE ADMIN',
    removeAdminPermissions: 'Remove from Admin',
    userPermissions: 'AUTHENTICATE',
    userDetails: 'DETAILS',
    setArchive: 'Archive',
    goPayment: 'Go Payment',
    unarchive: 'Unarchive',
    seeMessages: 'View Messages',
    deleteSelectedCategory: 'DELETE SELECTED',
    sampleFile: 'DOWNLOAD SAMPLE FILE',
    surveyAddAnswer: 'ADD NEW OPTION',
    allPermissionSelect: 'SELECT ALL',
    allPermissionDeselect: 'UNSELECT ALL',
    dowloadTempFile: 'Download Sample File',
    downloadFile: 'Download File',
    deleteFile: 'Delete File',
    addStock: 'Add Stock',
    change: 'CHANGE',
    addProduct: 'Add Product',
    transfer: 'Transfer',
    reload: 'RELOAD',
    labels: {
      airway: 'Airline',
    },
    unban: 'Unban The User',
    createShipment: 'Create Shipment',

    skipThisStep: 'Skip This Step',

    next: 'Next',
    back: 'Back',

    searchPrice: 'Search For Price',
    getReportData: 'GET REPORT DATA',
  },

  labels: {
    queryCount: 'Query Count',
    comissionCurrency: 'Commission Currency',
    freePackage: 'Free Package',
    currentSubscription: 'Current Subscription',
    upgrade: 'Upgrade',
    subscribe: 'Subscribe',
    annualPrice: 'Annual Price',
    subscriptionManagement: 'Subscription Management',
    portalMonthlyPrice: 'Portal User Monthly Price',
    portalCommissionDiscountRate: 'Portal User Commission Discount Rate',
    limitlessMonthlyQuery: 'Unlimited Monthly Query',
    limitlessOrderCount: 'Unlimited Order Count',
    specialCompanies: 'Select Company',
    stateCodes: 'Click to view State Codes',
    source: 'Source',
    agency: 'Agency',
    periodicPrice: 'Periodic Express Freight Difference',
    periodicLabel: 'Periodic',
    periodic: 'Periodic Additional Cost',
    labelUrl: 'Label Retrieval URL',
    maxPortalCount: 'Maximum Portal Count',
    monthlyPrice: 'Monthly Price',
    yearlyPrice: 'Yearly Price',
    accounts: 'Sub-Supplier Account',
    domesticCommissionType: 'Yurtiçi Komisyon Tipi',
    domesticCommission: 'Yurtiçi Navlun Komisyon Oranı',
    airlineCommissionType: 'Airline Commission Type',
    airlineCommission: 'Airline Freight Commission Rate',
    seawayCommissionType: 'Seaway Commission Type',
    seawayCommission: 'Seaway Freight Commission Rate',
    expressCommissionType: 'Express Commission Type',
    expressCommission: 'Express Freight Commission Rate',
    roadwayCommissionType: 'Roadway Commission Type',
    roadwayCommission: 'Roadway Freight Commission Rate',
    railwayCommissionType: 'Railway Commission Type',
    railwayCommission: 'Railway Freight Commission Rate',
    integrationUnitPrice: 'Integration Unit Price',
    percentage: 'Percentage',
    orderCount: 'Exclusive Order Quantity',
    monthlyQueryCount: 'Monthly Query Quantity',
    companyPackage: 'Company Package',
    moduleList: 'Module List',
    companyCategory: 'Company Category',
    reportType: 'Rapor Türü',
    etgbBankInfo: 'Exporter bank information',
    etgbDocuments: 'Documents that will accompany the goods',
    onlyWire: 'Only transfer payments can be confirmed.',
    cannotSelectExitDate:
      'The warehouse exit date cannot be selected because the payment was not confirmed',
    showLogoInLabel: 'Show Logo In Label',
    downloadSuccessfully: 'Downloaded Successfully',
    downloadData: 'Download Data',
    additionalCostType: 'Additional Cost Type',
    notAvailable: 'Not Available',
    fileStatus: 'File Status',
    widthLengthHeight: 'Width / Length / Height',
    sender: 'Sender',
    senderName: 'Sender Name',
    priceListName: 'Price List Name',
    customerList: 'Customer List Name',
    showLabel: 'Inspect Label',
    invoiceNo: 'Invoice Number',
    purposeOfShippingLabel: 'Purpose of Shipping',
    purposeOfShippingGift: 'Gift',
    purposeOfShippingSample: 'Sample',
    purposeOfShippingNotSold: 'Not Sold',
    purposeOfShippingPersonalEffects: 'Personal Effects',
    purposeOfShippingRepairReturn: 'Repair and Return',
    purposeOfShippingSold: 'Sold',
    IOSS: 'IOSS',
    saveSuccess: 'Saved Successfully',
    fileNavDescription:
      'You are being directed to download the labels and upload the documents.',
    closeWithoutSave:
      'Are you sure you want to close without saving? Your changes will be lost.',
    atLeastOneDate: 'At least one date must be selected.',
    damageNotification: 'Damage Notification',
    otherNotification: 'Other',
    promotionalText:
      'I agree to receive promotional emails, texts, or calls from Algönder and to have my contact information stored for this purpose.',
    contactPermissionText:
      'I agree to receive promotional emails,texts or calls from Algönder and to have my contact information stored for this purpose. I can unsubscribe and withdraw my consent at any time.',
    customerName: 'Customer Name',
    customerInsurance: 'Customer Insurance',
    supplierInsurance: 'Supplier Insurance',
    serviceProviderInsurance: 'Service Provider Insurance',
    insurance: 'Insurance',
    otherServices: 'Other Services',
    orderFileSubSupplierUploaded: 'Uploaded',
    orderFileSubSupplierNotUploaded: 'Not Uploaded',
    damageFileType: 'Damage File Type',
    loadingInstruction: 'Loading Instruction',
    proformaFileType: 'Proforma',
    eArchiveInvoice: 'E-Archive Invoice',
    etgbForm: 'ETGB Form',
    eInvoice: 'E-Invoice',
    etgbForm: 'ETGB Form',
    otherFileType: 'Other File Type',
    tooltipDateTime: 'Add Date Time',
    Term: 'Term',
    Meaning: 'Meaning',
    AddTerm: 'Add Term',
    AddFAQ: 'Add FAQ',
    EditFAQ: 'Edit FAQ',
    EditTerm: 'Edit Term',
    Trace: 'İzle',
    None: 'Other',
    All: 'All',
    Emergency: 'Emergency',
    Alert: 'Warning',
    Alerts: 'Warnings',
    Critical: 'Critical',
    Error: 'Error',
    Warning: 'Warning',
    Notice: 'Notice',
    Informational: 'Informational',
    Debug: 'Degbug',
    error: 'Error',
    informational: 'Information',
    warning: 'Warning',
    dateRange: 'Date filter',
    emptyCart: 'Empty Basket',
    accepted: 'Accepted',
    canceled: 'Denied',
    level: '{value} Level',
    waiting: 'Waiting Approval',
    required: '{value} field must be entered.',
    type: 'Keyword',
    date: '{value} Date',
    dateAsNoun: 'Date',
    offerCount: 'Offer Count',
    code: 'Code',
    number: '{number} numbered {field}',
    name: 'Name',
    address: 'Address',
    appTitle: 'AlGonder Subscription',
    authorizationFailed: 'Authorization Failed',
    test: 'Test It',
    SecretKey: 'Secret Key',
    postalCode: 'Postal Code',
    postalCode_from: 'Beginning City Postal Code',
    postalCode_to: 'Destination City Postal Code',
    country: 'Country',
    countryCode: 'Country Code',
    role: 'Signature Authority',
    warehouse: 'Warehouse',
    creditCard: {
      number: 'Credit Card Number',
      ccv: 'Security Code',
      month: 'Month',
      year: 'Year',
    },
    marketplace: 'Marketplace',
    Integration: {
      title: 'Integration',
    },
    logo: 'Logo',
    creator: 'Creator',
    status: 'Status',
    company: {
      officerName: 'User Name',
      officerSurname: 'User Lastname',
      taxOffice: 'Tax Office',
      taxNo: 'Tax Number / Personal Identity Number',
      tradeRegisterNumber: 'Trade Registration Number',
      industryRegisterNumber: 'Industrial Registration Number',
    },
    user: {
      singular: 'User',
      plural: 'Users',
      firstName: 'First Name',
      lastName: 'Last Name',
    },
    serviceType: 'Service Type',
    loadType: 'Load Type',
    loadAddress: 'Load Address',
    howDidYouHearAboutUs: 'Where Did You Hear Us From?',
    fromWhere: 'From?',
    fromWhereCity: 'From City',
    toWhere: 'To?',
    containerType: 'Container Type',
    containerCount: 'Container Count',
    isDangerousMaterial: 'Dangerous materials.',
    dangerousMaterialLevel: 'Dangerous Material Level',
    toAirport: 'To The Airport',
    fromAirport: 'From The Airport',
    doorCount: 'Number of Packages',
    kilo: 'Weight/Package (kg)',
    volume: 'Volume (m3)',
    kgShorten: 'kg',
    m3Shorten: 'm3',
    totalKilo: 'Total Weight (kg)',
    totalVolume: 'Total Volume (m3)',
    cargoDefinition: 'Cargo Definition',
    myLoadWillNotBeStacked: 'Not Stackable',
    vehicleType: 'Vehicle Type',
    vehicleCount: 'Nbr of Vehicle',
    toSeaport: 'To The Seaport',
    fromSeaport: 'From The Seaport',
    toStation: 'To The Station',
    fromStation: 'From The Station',
    carType: 'Railroad Car Type',
    carCount: 'Railroad Car Count',
    width: 'Width (cm)',
    height: 'Height (cm)',
    length: 'Length (cm)',
    gTypeNumber: 'G Type Number',
    productType: 'Type of Goods',
    rowsPerPage: 'Rows Per Page',
    targetPrice: 'Target Price',
    currency: 'Currency',
    note: 'Note',
    offerType: 'Offer Type',
    filter: 'Filter',
    offeredPrice: 'Offered Price',
    day: {
      singular: 'Day',
      plural: 'Days',
    },
    expiryDate: 'Expiry Date',
    expiryDateStart: 'Expiry Date Start',
    expiryDateEnd: 'Expiry Date End',
    buyer: 'Consignee',
    referenceNumber: 'Ref. Number',
    rating: 'Rating Value',
    including: 'Including {value}',
    excluding: 'Excluding {value}',
    tax: {
      singular: 'Tax',
      plural: 'Taxes',
    },
    cargo: 'Cargo',
    express: 'Express',
    chatStatus: 'Chat Status',
    downloaded: 'Downloaded',
    notDownloaded: 'Not Downloaded',
    labelsOrderStatuses: {
      detail: 'In Draft',
      supplier: "At Transporter's Approval",
      payment: 'Waiting For Payment',
      approved: 'Ready to Load',
      loaded: 'Loaded',
      shipping: 'Shipping',
      willBeComingToWarehouse: 'It Will Be Coming To Warehouse',
      inWarehouse: 'At Warehouse',
      outWarehouse: 'Out of Warehouse',
      inDestination: 'At Destination',
      delivered: 'Delivered',
      archived: 'Archived',
      other: 'Undetermined',
      canceled: 'Canceled',
    },
    fileStatuses: {
      notPlanned: 'Not planned',
      pickupPlanned: 'Pickup planned',
      inWarehouse: 'In Warehouse',
      loading: 'In Loading',
      invoiced: 'Invoiced',
      atDestination: 'At Destination',
      delivered: 'Delivered',
    },
    selectReport: 'Select A Report Type',
    companyType: 'Company Type',
    accountType: 'Account Type',
    customer: 'Customer',
    sender: 'Sender',
    has: 'Has Authority',
    hasNot: 'Has Not Authority',
    authority: 'Authority',
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
    hasSignAuthority: 'Authorized Signatory',
    hasNotSignAuthority: 'No Authorized Signatory',
    myTickets: 'My Tickets',
    ticketStatus: 'Ticket Status',
    secondAddress: 'Second Address',
    stationToStation: 'Station To Station',
    paymentStatus: 'Payment Status',
    paymentCompleted: 'Paid',
    paymentIncomplete: 'Awaiting Payment',
    yourOffer: 'Your Offer',
    yourOfferCurrency: "Your Offer's Currency",
    region: 'Region',
    regions: 'Regions',
    priceList: 'Price List',
    regionList: 'Region List',
    isActive: 'Active',
    default: 'Default',
    pricing: 'Pricing',
    operation: 'Operation',
    noPrice: 'Price Not Set',
    isTransshipment: 'Transshipment',
    transshipmentCount: 'Transshipment Count',
    waitingForApproval: 'Waiting For Approval',
    currentExpiryDate: 'Current Expiry Date',
    currentTransportTime: 'Current Transport Time',
    newExpiryDate: 'New Expiry Date',
    newTransportTime: 'New Transport Time',
    expired: 'Expired',
    website: 'Website',
    approved: 'Approved',
    approvedAccount: 'Approved Account',
    readyForShipping: 'Waiting For Shipping',
    shipped: 'Shipped',
    paid: 'Paid',
    paymentProgressing: 'Payment Proccessing',
    fullyRefunded: 'Full Refund',
    partiallyRefunded: 'Partial Refund',
    receiptDetail: 'Receipt Detail',
    volumeWeight: 'Volume Weight',
    chargeableWeight: 'Chargeable Weight(kg.)',
    lademeter: 'Lademeter',
    rejectApprovement: 'Cancel The Payment Approval',
    requestDate: 'Request Date',
    paymentTarget: 'Payment Target',
    returnToUserList: 'Return to your user',
    loginAs: 'Login As Customer',
    addPortalUser: 'Add Portal User',
    addAdditionalCost: 'Add Additional Cost',
    updateAdditionalCost: 'Update Additional Cost',
    editPortalUser: 'Edit Portal User',
    listName: 'List Name',
    cancelInformation: 'Cancel Information',
    sendToSupplier: 'Send To Supplier',
    piece: 'Piece',
    direction: 'Sending Direction',
    createComplaint: 'Create Complaint',
    yourComplaint: 'Your Complaint',
    dimensionSummary: 'Dimension Summary',
    isHome: 'Is this address for a residence?',
    expressInformation: 'Express Transportation Information',
    goodsPrice: 'Goods Price',
    taxPayer: 'Tax Payer',
    dap: 'DAP (Buyer)',
    ddp: 'DDP (Sender)',
    payingType: 'Payment Type',
    upFront: 'Up Front',
    cashOnDelivery: 'Cash On Delivery',
    intermediateAgency: 'Intermediate Agent',
    packageType: 'Package Type',
    document: 'Document',
    package: 'Package',
    ETGBExpress: 'ETGB Express',
    ETGBEconomy: 'ETGB Economy',
    ExitWithDeclaration: 'Express Exit With Declaration',
    Economic: 'Declarative Economy Express',
    ExpressSample: 'ETGB Sample',
    sample: 'Sample',
    expressWarning:
      'Dangerous and non-stackable products cannot be transported by express',
    outOfArea: 'Out of Area',
    import: 'Import',
    export: 'Export',
    supplierBankInfo: 'Supplier Bank Information',
    addBankInfo: 'Add Bank Information',
    editBankInfo: 'Edit Bank Information',
    accountOwner: 'Account Owner',
    surchargeInformation: 'Surcharge Information',
    bankInformation: 'Bank Information',
    timeOut0: 'First Warning Time',
    timeOut1: 'Second Warning Time',
    timeOut2: 'Third Warning Time',
    timeOut3: 'Cancellation Time',
    approvedByAdmin: 'Approved By Admin',
    notApprovedByAdmin: 'Not Approved By Admin',
    adminApproval: 'Admin Approval',
    estimateGreaterSelected:
      'The estimated delivery date you choose exceeds the transit time',
    estimateGreaterAuto: 'The estimated delivery date exceeds the transit time',
    cancelDescription: 'Cancel Description',
    cancelReason: 'Cancel Reason',
    Other: 'Other',
    ConfirmationNotRecived: 'Confirmation Not Received',
    DocumentsMissing: 'Documents Missing',
    InformationChanged: 'Information Changed',
    PaymentNotReceived: 'Payment Not Received',
    DeliveryProblems: 'Delivery Problems',
    contract: 'Contract',
    addAddress: 'Add Address',
    maxZipCode: 'Ending Zip Code',
    minZipCode: 'Starting Zip Code',
    minPrice: 'Minimum Price',
    pricePerKg: 'Price Per Kg',
    selectOnMap: 'You can select a location by clicking on the map.',
    generalOrderInformation: 'Genel Sipariş Bilgileri',
    carrierCompanyInfo: 'Sender / Receiver',
    packageInformation: 'Koli Bilgileri',
    orderDates: 'Dates',
    noNewMessage: 'You have no new messages',
    noNewOffer: 'You have no new offers',
    noNewPending: 'You have no pending payments',
    noNewApproval: 'You have no pending payment approvals',
    noNewSpotOrder: 'You do not have any new price requests',
    stateCode: 'State Code',
    ClientId: 'Client ID',
    ClientSecretKey: 'Client Secret',
    ChildKey: 'Child Key',
    ChildSecret: 'Child Secret',
    AccountNumber: 'Account Number',
    GrantType: 'Grant Type',
    LogisticId: ' Logistic ID',
    minLength: 'Minimum 6 characters',
    maxLength: 'Maximum 24 characters',
    uppercase: 'At least one uppercase letter',
    lowercase: 'At least one lowercase letter',
    digit: 'At least one number',
    special: 'At least one special character',
    noAccount: 'Don’t have an account?',
    loadingFile: 'Loading instruction',
    newMessage: 'You have {value} new messages',
    ordersWaitingPayment: 'Orders Waiting Payment',
    ordersWaitingPaymentMessage: 'You have {value} orders awaiting payment',
    PendingApprovalCompanyCount: 'Pending Approval Company Count',
    PendingApprovalCompanyCountMessage:
      'You have {value} pending approval company',
    noNewPendingApprovalCompanyCount: 'You have no pending approval company',
    UnSolvedDemandCount: 'Unsolved Demand Count',
    UnSolvedDemandCountMessage: 'You have {value} unsolved demand',
    noNewUnSolvedDemandCount: 'You have no unsolved demand',
    ordersWaitingApproval: 'Pending Approval',
    ordersWaitingApprovalMessage: 'You have {value} orders awaiting approval',
    newShippings: 'New Shipment Requests',
    newShippingsMessage: 'You have {value} new shipment request(s)',
    spotOrderMessage: 'You have {value} new offer(s)',
    newOffer: 'Offers',
    newOfferMessage: 'You have {value} offers',
    noFileTitle: 'File name not found.',
    transferDate: 'Transfer Date',
    amount: 'Amount',
    accountNumber: 'Account Number',
    fullName: 'Full Name',
    bankName: 'Bank Name',
    addInvoice: 'Add Invoice',
    showInvoice: 'Show Invoice',
    showInvoiceDetail: 'Show Invoice Detail',
    invoice: 'Invoices',
    editInvoice: 'Edit Invoice',
    deleteInvoice: 'Delete Invoice',
    equipmentDetails: 'Equipment Details',
    editEquipment: 'Edit Equipment',
    softwareSupport: 'Software Support',
    selectFile: 'Select a File',
    fileNote: 'File Note',
    fileType: 'File Type',
    fileUploadDate: 'File Upload Date',
    fileSubSupplierUploadStatus: 'Sub Supplier Upload Status',
    noFileNote: 'File Note Not Entered',
    fileName: 'File Name',
    tableActions: 'Actions',
    showNotes: 'Show Notes',
    mediaFiles: 'Media Files',
    endTicket: 'End the Ticket',
    ticket: 'Support Ticket',
    myAddresses: 'My Addresses',
    supplierRating: 'Supplier Rating',
    noDate: 'Not Assigned',
    orders: '',
    spotOffers: 'Offers',
    smsNotification: 'I want to be notified by SMS',
    emailNotification: 'I want to be notified by e-mail',
    newSupport: 'Create New Support Request',
    demandTitle: 'Demand Title',
    subject: 'Subject',
    orderId: 'Order ID',
    companyUpdateString: 'Company information',
    companyFileTypeTaxBoard: 'Tax Board',
    companyFileTypeCircularOfSignature: 'Circular of Signature',
    companyFileTypeCertificateOfRegistry: 'Certificate of Registry',
    companyFileTypeArticlesOfIncorporation: 'Articles of Incorporation',
    termsAndConditions: 'Terms of Use ',
    privacyPolicy: 'Privacy Policy',
    consent: 'Explicit Consent',
    read: 'has been read and accepted.',
    termsOfUse: 'Terms of Use',
    transfer: 'Transfer',
    plannedLabel: 'Planned',
    notPlannedLabel: 'Not Planned',
    creditCardOrder: 'Credit Card',
    currencyWarning: 'Please select a currency to make the payment.',
    fillIn: 'Please make sure to fill in all mandatory settings.',
    customerNote: 'Customer Note',
    supplierNote: 'Supplier Note',
    signature:
      'Before signing the contract, you must upload your signature to the system.',
    research: 'RESEARCH',
    readAccept: 'I Have Read and Confirmed the Agreement',
    preparingAgreement: 'Preparing Agreement',
    dangerSubstance: 'Includes Dangerous Substance',
    cap: 'Cap',
    addNewFile: 'Add New File',
    createETGBform: 'Create ETGB Form',
    noNote: 'Note Not Entered',
    addNewEquipment: 'Add New Equipment',
    addLocation: 'Add Location',
    addStatus: 'Add Status',
    equipmentNo: 'Equipment No',
    equipmentType: 'Equipment Type',
    goodsType: 'Goods Type',
    lastLogin: 'Last Login',
    lastLocation: 'Last Location',
    lastLatitude: 'Last Latitude',
    lastLongitude: 'Last Longitude',
    loadedKg: 'Loaded Weight',
    loadedVolume: 'Loaded Volume',
    sealNo: 'Seal No',
    airline: 'Airline',
    airway: 'Airline',
    roadway: 'Roadway',
    seaway: 'Seaway',
    railway: 'Railway',
    partial: 'Partial',
    complete: 'Complete',
    FCL: 'FCL',
    LCL: 'LCL',
    FTL: 'FTL',
    LTL: 'LTL',
    wait: 'Waiting',
    onTheWay: 'On The Way',
    delivered: 'Delivered',
    carrierInfo: 'Carrier Info',
    orderActionStatusLog: 'Order Actions',
    orderStatusLogs: 'Order Status Logs',
    actions: 'Action',
    locationHistory: 'Location History',
    locationDesc: 'Location Description',
    addAction: 'Add Action',
    addActionFromExcel: 'Add Action From Excel',
    latitude: 'Latitude',
    longitude: 'Longitude',
    location: 'Location Name',
    locationDate: 'Location Date',
    doorToDoor: 'Door To Door',
    portToPort: 'Port To Port',
    doorToPort: 'Door To Port',
    airportToAirport: 'Airport To Airport',
    addressToAddress: 'Address To Address',
    addSubCompany: 'Add Sub Company',
    editSubCompany: 'Edit Sub Company',
    address1: 'Address Line',
    address2: 'Additional Address Line',
    authorizedPerson: 'Authorized Person',
    EORICode: 'EORI Code',
    IBAN: 'IBAN',
    algönderRefNo: 'Algönder Reference No',
    loadStatus: 'Load Status',
    supplier: 'Supplier',
    unknown: 'Unknown',
    shipmentType: 'Shipment Type',
    sourceCountry: 'Unloading Country',
    sourceCity: 'Unloading City',
    sourcePostalCode: 'Unloading Postal Code',
    destinationCountry: 'Loading Country',
    destinationCity: 'Loading City',
    destinationPostalCode: 'Loading Postal Code',
    loadingPort: 'Loading Port',
    unloadingPort: 'Unloading Port',
    loadingStation: 'Loading Station',
    unloadingStation: 'Unloading Station',
    loaderCompany: 'Loader Company',
    buyerCompany: 'Buying Company',
    loaderAddress: 'Loader Address',
    buyerAddress: 'Buyer Address',
    loaderAddress2: 'Loader Address 2',
    buyerAddress2: 'Buyer Address 2',
    customerRefNo: 'Customer Reference No',
    subSupplierTrackingNo: 'Sub Supplier Tracking No',
    paymentType: 'Payment Type',
    numOfContainers: 'Number of Containers',
    kg: 'Weight (Kg)',
    m3: 'Volume (m3)',
    estimationDate: 'Estimated Arrival Date',
    desiredDate: 'Desired Loading Date',
    loadingDate: 'Loading Date',
    departureDate: 'Departure Date',
    arrivalDate: 'Arrival Date',
    deliveryDate: 'Delivery Date',
    paymentDate: 'Payment Date',
    hazmatDescription: 'Hazmat Description',
    price: 'Price',
    payOptions: 'Payment Options',
    pastResearchSingular: 'Past Research',
    pastResearchPlural: 'Past Researches',
    orderDate: 'Order Date',
    orderTime: 'Order Time',
    cutOffHour: 'Cut Off Hour - Hours',
    loadingDeadline: 'Loading Deadline - Working Days',
    latestNotifyTime: 'Notify Deadline - Working Hours',
    termDay: 'Deadline - Days',
    penaltyPerDay: 'Daily Late Fee',
    email: 'E-Mail',
    brand: 'Brand',
    Creator: 'Creator',
    sicilNumber: 'Registration Number',
    password: 'Password',
    search: 'Enter Text To Search',
    companyTitle: 'Company',
    companyName: 'Company Name',
    productGroup: '',
    companyAddress: 'Company Address',
    receiptAddress: 'Receipt Address',
    receiptAddressInfo: 'My billing address is the same as my company address.',
    isRate: 'I wish to receive ratings from the customers',
    isLabelShown: 'My logo visible on the label',
    yourMessage: 'Your Message',
    companyLogo: '',
    city: 'City',
    district: 'District',
    neighbourhood: '',
    phone: 'Phone',
    groups: 'Groups',
    nameSurname: 'Name Surname',
    yourName: 'Your Name',
    lastname: 'Lastname',
    yourLastname: 'Your Lastname',
    userName: 'Username',
    sellerCode: 'Vendor Code',
    bayiEmail: 'Vendor E-mail',
    modulName: 'Module Name',
    modulRoutue: '',
    modulIsStatic: '',
    modulKey: 'Module Key',
    modulDescription: 'Module Description',
    groupType: 'Group Type',
    groupName: 'Group Name',
    allGroupTypes: 'All',
    userBlocked: 'Your user has been blocked.',
    userRemainingLogin: 'Rights remaining',
    userUsername: 'Username (Registration)',
    userPassword: 'Password',
    newPassword: 'New Password',
    userPasswordAgain: 'Re-Password',
    newPasswordAgain: '',
    userFirstName: 'First Name',
    userLastname: 'Last Name',
    userEmail: 'Email',
    userPhone: 'Phone',
    formUserPhone: 'Existing Phone',
    userPhonePlaceHolder: 'Enter your phone number without area code',
    userStatus: 'User Status',
    userType: 'User Type',
    userAbout: 'About User',
    header: 'Header',
    chooseKeyFirst: 'Make Primary',
    deleteKey: 'Delete',
    category: 'Category',
    tags: 'Tags',
    categoryNameTR: 'Category Turkish Name',
    categoryNameEN: 'Category English Name',
    mainPicture: 'Main Image',
    max5: 'Maximum 5 Items',
    max15: 'Maximum 15 Items',
    userSearch: 'Name',
    tooltipNotifyDamage: 'Notify Damage',
    tooltipShowSupport: 'Show Ticket Detail',
    tooltipDeleteSupport: 'Delete Ticket',
    tooltipCustomFiels: 'Go to Custom Field',
    tooltipEdit: 'Edit',
    tooltipInspectDocument: 'Inspect Document',
    tooltipInspectDocumentNote: 'Inspect Document Note',
    tooltipDocumentAssign: '',
    tooltipDocumentAssignUpdate: '',
    tooltipDeny: 'Deny',
    tooltipAccept: 'Accept',
    tooltipAnswer: 'Answer',
    tooltipAnalyse: 'examine',
    tooltipAnswered: 'Answered',
    tooltipDelete: 'Delete',
    tooltipDownloadLabel: 'Download',
    tooltipDeleteAssignment: '',
    surveyType: 'Survey Type',
    documentType: '',
    assignmentType: '',
    documentTypeAssigned: '',
    documentTypeUnassigned: '',
    documentTypePrivate: '',
    documentTypePublic: '',
    contentType: '',
    contentStatus: '',
    questionType: 'Question Type',
    questionStatus: 'Question Status',
    makeAdmin: 'Make Admin',
    authorizeUser: 'Authorize',
    unblockUser: '',
    fileUpload: 'Add Files or Media',
    fileUploadCompulsory: 'Add Files or Media (Compulsory)',
    storyFileUpload: 'Add Story Image',
    storyFileUploadDescription:
      'Up to 50 MB, portrait format, recommended resolution 900x1200',
    fileUploadDescription:
      'Maximum 20 MB, 4:3 ratio, recommended resolution 1200x900.',
    photoUpload: 'Add Photo (Required)',
    storyPhotoUpload: 'Add Icon Photo (Required)',
    storyphotoUploadDescription:
      'Maximum 20 MB, square format, recommended resolution 900x900.',
    photoUploadDescription:
      'Maximum 1 MB, 4:3 ratio, recommended resolution 1200x900.',
    photoUploadNotifications: 'Upload Photo',
    description: 'Description',
    contact: 'Contact',
    image: 'Image',
    sortOrder: 'Sort',
    postType: 'Content Type',
    orderInfo: 'Order Info',
    content: 'Content',
    postCategory: 'Category',
    postCategoryRequired:
      ' (It is mandatory to select one from each main category)',
    postTag: 'Label',
    postGroup: 'Group',
    postDivision: 'Division',
    postCustomField: 'Custom Field',
    selectExcelFile: 'Choose a food menu',
    tooltipActive: 'Active',
    tooltipPassive: 'Passive',
    tooltipResolved: 'Resolved',
    tooltipUnresolved: 'Unresolved',
    foodMenuCalory: 'Calories',
    selectCorp: 'Choose company',
    selectLoc: 'Select location',
    selectEmpType: 'Select Employee Type',
    selectItemsPerPage: 'Choose number of data per page',
    marketplaceLabel: 'Marketplace',
    addMarketplace: 'Add Marketplace',
    editMarketplace: 'Edit Marketplace',
    addIntegration: 'Add Integration',
    editIntegration: 'Edit Integration',
    ShipmentCompany: 'Shipment Company',
    selectDate: 'Select Date',
    selectTime: 'Select Time',
    selectDateRange: 'Select Date Range',
    tooltipPublished: 'Live',
    tooltipDraft: 'Pending',
    tooltipReview: 'In review',
    tooltipUnpublished: 'Unpublished',
    tooltipReject: 'Rejected',
    answerQuestion: 'Answer Question',
    inspectQuestion: 'Inspect Question',
    questionAnswer: 'Question Answer',
    questionHr: 'Question',
    titleHr: 'Title',
    filesHr: 'Files',
    dateHr: 'Reply Date',
    noFileHr: 'No File',
    statusHr: 'Question Status',
    spotOffer: 'Offer',
    order: 'Order',
    tooltipNoAnswered: 'Not Answered',
    tooltipInReview: 'In Review',
    selectboxType: 'Please select data type',
    inputName: 'Name (Name field must be unique)',
    parentCategory: 'Parent Category',
    surveyName: 'Survey Name',
    prohibitedWord: '',
    surveyStatus: 'Survey Status',
    surveyPublishDate: 'Publish Date',
    surveyEndDate: 'End Date',
    surveyQuestion: 'Survey Question',
    surveyAnswer: 'Answer',
    surveyLink: 'Link',
    selectPage: 'Select Page',
    selectStatus: 'Select Status',
    selectWareHouse: 'Warehouse',
    selectCountPostType: 'Select Post Type',
    inputTagId: 'Tag Id',
    selectInnerPage: 'Select the redirected page',
    modulChoose: 'Choose Page to Redirect',
    dateAndLinks: 'Date and Link',
    dateAndPin: 'Date and Pin',
    link: 'Link',
    notificationUsers: 'Users',
    documentName: '',
    documentContent: '',
    notificationTargetTypes: 'Person(s) and Group(s) to be forwarded',
    testNotification: '',
    notificationDateTime: 'Submission Date and Time',
    notificationAllTargets: 'Send to all contacts and groups.',
    notificationTestMessage: '',
    notificationSendMessage: '',
    notificationUserSelect: 'Select users',
    notificationLink: 'Enter the link to open in the application.',
    notificationExternalLink: 'Enter the link to open outside the application.',
    notificationMessage: 'Message',
    notificationTitleDescription: 'Content and Message',
    storyTitle: 'Story Title',
    surveyDownload: 'Download Survey Results',
    currentPassword: 'Current Password',
    reNewPassword: 'Re-type New Password',
    changePassword: 'Change Password',
    changeNumber: 'Change Your Phone',
    hardReload: 'Reload Page',
    useGuide: 'User Guide',
    searchInTitle: 'Search to Title',
    noCategory: 'No Category',
    searchCategory: 'Type for Searching Category',
    noCategorySelected: 'Category not selected',
    unPublish: 'Unpublish',
    publishItem: 'Publish',
    batnews: '',
    bspPlus: '',
    brandName: 'Brand Name',
    orderNo: 'Order No',
    orderAmount: 'Order Amount',
    customerFullName: 'Customer',
    customerInformation: 'Customer Information',
    dangerSubstanceLabel: 'Hazardous Material Fee Information',
    customerCompanyName: 'Customer Company Name',
    customerEmail: 'Customer Email',
    customerPhone: 'Customer Phone',
    receiverAddress: 'Receiver Address',
    shippingCompany: 'Shipping Company',
    shippingNo: 'Shipping No',
    shippingDate: 'Shipping Date',
    shippingPrice: 'Shipping Price',
    appeal: 'Appeal',
    appLoginTitle: 'User Login',
    smsVerificationCode: 'Verification Code',
    searchProduct: 'Search Product',
    notifications: 'Notifications',
    readAll: 'READ ALL',
    bottomNavTitle: 'Powered by — ',
    applicationName: 'AlGonder',
    addNew: 'Add New',
    welcomeText: 'Welcome back,  ',
    goToOrder: 'Go To Order',
    messageLanguagePreference: 'SMS and Mail Language Preference',
    languagePreference: 'Language Preference',
    waitingCustomer: 'Waiting for Customer',
    waitingSupplier: 'Waiting for Supplier',
    waitingContract: 'Waiting for Contract',
    completed: 'Completed',
    loaded: 'Loaded',
    onRoad: 'On the Road',
    arrival: 'Arrival',
    archived: 'Archived',
    rate: 'Rate',
    exhibition: 'Exhibition',
    reference: 'Reference',
    personal: 'Personal',
    corporate: 'Corporate',
    currentPhoneNumber: 'Current Phone Number',
    newPhoneNumber: 'New Phone Number',
    verificationCode: 'Verification Code',
    profileInformation: 'Profile Information',
    userGuide: 'Website Usage',
    startDate: 'Start Date',
    invoiceDate: 'Invoice Date',
    endDate: 'End Date',
    orderStatus: 'Loading Status',
    orderAndFileStatus: 'Upload / File Status',
    orderShipmentType: '',
    orderFrom: '',
    orderTo: '',
    productName: 'Product Name',
    productDescripton: 'PRoduct Description',
    Barcode: 'Barcode',
    SKUNo: 'SKU No',
    productDimensions: 'Dimensions',
    productTimes: 'Times',
    productMaxOrderCount: 'Max Order Count',
    productCriticalStockCount: '',
    productGTIN: '',
    productCost: '',
    warehouseManagement: 'Warehouse Management',
    stockCount: '',
    productIntroduction: '',
    ready: 'Ready',
    file: 'File',
    preparing: 'Preparing',
    getError: 'Error Received',
    Width: 'Width (cm)',
    Length: 'Length (cm)',
    Height: 'Height (cm)',
    Weight: 'Weight (kg)',
    preparingTime: 'Preparing Time (day)',
    warrantyTime: 'Warranty Time (month)',
    selectPhoto: 'Select Photo',
    live: 'Live',
    notLive: 'Not Live',
    onSale: 'On Sale',
    notOnSale: 'Not On Sale',
    priceChange: 'Price Change',
    criticalStock: 'Critical Stock Exceeded',
    hasStock: 'Stock In',
    hasNotStock: 'Out of Stock',
    changePercentage: '',
    cost: 'Cost',
    checkboxIsUpdateOrder: 'Completed the changes',
    chatSupport: 'Support Crew',
    addBox: 'Add Package',
    editBox: 'Edit Package',
    from: 'From',
    to: 'To',
    fromWhereDashboard: 'From / To',
    newSpotOffersHeader: 'New Offers',
    landway: 'RoadWay',
    hourAsNoun: '',
    codeAsNoun: '',
    locationAsNoun: '',
    recordDate: 'Record Open Date',
    requestedPurchaseDate: 'Requested Purchase Date',
    pickupType: 'Pickup Type',
    productionCountry: 'Production Country',
    packagingType: 'Packaging Type',
    portalSellerCode: 'Seller Code',
    deferredPayment: 'Deferred Payment',
    envelope: 'Envelope',
    origin: 'Origin',
    maxKg: 'Maximum Kg.',
    marketplaceLoginURL: 'Marketplace Login URL',
    receiveFromDoor: 'Receive From Address',
    productPackageType: 'Container Type',
    expenseType: 'Expense Type',
    proformaOption: 'Proforma Option',
    customerProforma: 'Customer Proforma',
    supplierProforma: 'Supplier Proforma',
    pickupTypes: {
      contactWithFedEx: "I'm going to contact FedEx.",
      leavingPackageToFedEx: 'I am going to leave this package at FedEx.',
      scheduledPickup: 'This is a scheduled pickup.',
    },
    fsc: 'FSC',
    cargoCompanyName: 'Cargo Company Name',
    cargoTrackingNumber: 'Cargo Tracking Number',
    transportCompanyName: 'Transport Company Name',
    transportPlate: 'Transport Plate',
    transportDriverName: 'Transport Driver Name',
    selfSendCargo: 'I will send it by cargo',
    selfSendHaul: 'I will send it by freight',
    orderSender: 'Order Sender',
    pickupOrganisation: 'Pickup Organisation',
    deliveryInfo: 'Delivery Information',
    customerType: 'Customer Type',
    memberType: 'Member Type',
    tooltipFileOperations: 'File Operations',
    tooltipDownloadLabelSingular: 'Download Label',
    tooltipCorporate: 'Corporate Customer',
    tooltipMarketPlace: 'Marketplace Customer',
    portalCustomerCode: 'Supplier Customer Code',
    generatePassword: 'Give Password Automatically',
    portalUsers: 'Portal Users',
    accountantOrganisation: 'Accountant Organisation',
    warehouseOrganisation: 'Warehouse Organisation',

    entranceToWarehouseDate: 'Entrance To The Warehouse Date',
    exitFromWarehouseDate: 'Exit From The Warehouse Date',

    countInCap: 'Count In Cap',

    additionalCostType: 'Additional Cost Type',
    costParameter: 'Cost Parameter',
    value: 'Value',
    minValue: 'Minimum Value',

    environmentalMeasure: 'Environmental Measure',
    ddp: 'DDP',
    additionalHandling: 'Additional Handling',
    personalDelivery: 'Personal Delivery',
    oversize: 'Oversize',
    insurance: 'Insurance',
    signedDelivery: 'Signed Delivery',

    fixedPrice: 'Fixed Price',
    perOrder: 'Per Order',
    perPackage: 'Per Package',
    perWeight: 'Per Weight',
    perProductPrice: 'Product Price Percentage',

    issueInvoice: 'Issue The Invoice',

    invoiceIssued: 'Invoice Issued',
    invoiceNotIssued: 'Invoice Not Issued',

    planned: 'Plan',
    notPlanned: 'Cancel Plan',

    wantSignedDelivery: 'I want to signed delivery.',
    referenceId: 'Reference ID',

    mailLogo: 'Email Logo',

    customerListName: 'Customer List Name',
    exportImportType: 'Export Import Type',

    optionalReferenceID: 'Optional Reference ID',

    securityType: 'Security Type',
    webhookToken: 'Webhook Token',
    shipmentUpdateUrl: 'Shipment Update URL',
    trackingUrl: 'Tracking URL',

    eTGBExpress: 'ETGB Express',
    eTGBEconomy: 'ETGB Ekonomi',
    exitWithDeclaration: 'Exit With Declaration',
    economic: 'Economic',
    sampleShipping: 'Sample Shipping',

    basePrice: 'Base Price',
    circumference: 'Circumference',

    averageWeeklyLoadCount: 'Average Weekly Load Count',
    averageWeeklyLoadWeight: 'Average Weekly Load Weight(kg)',

    priceListName: 'Price List Name',
    results: 'Results',

    profit: 'Profit',
    additionalExpense: 'Additional Expense',
    additionalExpenseCurrency: 'Additional Expense Currency',

    showColumns: 'Fields to Display',
    isMailSent: 'Send Email',
    mailFrequency: 'Email Sending Frequency',
    willBeFilteredColumns: 'Fields to Filter',

    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',

    invoiceStatus: 'Invoice Status',

    roles: {
      Accountant: 'Accountant',
      Operation: 'Operation',
      Planner: 'Planner',
      Supplier: 'Supplier',
      PendingSupplier: 'Pending Approval User(Supplier)',
      Customer: 'Customer',
      PendingCustomer: 'Pending Approval User(Customer)',
    },

    packageDescription: 'Shipment Description',
    sourceCompany: 'Source Company',

    dynamicForm: {
      ulasim_tipi: 'Transportation Type',
      faturasi_kesildi: 'Invoice Issued',
      planlandi: 'Planned',
      dosya_durumu: 'File Status',
      transfer_tipi: 'Transfer Type',
      odeme_durumu: 'Payment Status',
      yukleme_durumu: 'Loading Status',
      siparis_tipi: 'Order Type',
      portal_musteri_tipi: 'Portal Customer Type',
      tehlikeli_madde_iceriyor: 'Contains Hazardous Materials',
      gonderici_ulke: 'Sender Country',
      alici_ulke: 'Recipient Country',
      eve_teslimat: 'Home Delivery',
      vergi_odeme: 'Tax Payment',
      odeme_tipi: 'Payment Type',
      sigorta: 'Insurance',
      alt_tedarikci: 'Subcontractor',
      servis_adi: 'Service Name',
      imzali_teslimat: 'Signed Delivery',
      CompanyType: 'Company Type',
      musteri_adi: 'Customer Name',
      kap_adedi: 'Package Count',
      toplam_kilo: 'Total Weight',
      toplam_hacim: 'Total Volume',
      fatura_agirligi: 'Invoice Weight',
      referans_kodu: 'Reference Code',
      gonderici_adi: 'Sender Name',
      gonderici_adres: 'Sender Address',
      gonderici_sehir: 'Sender City',
      alici_adi: 'Recipient Name',
      alici_adres: 'Recipient Address',
      alici_sehir: 'Recipient City',
      odeme_tarihi: 'Payment Date',
      yukleme_tarihi: 'Loading Date',
      cikis_tarihi: 'Departure Date',
      varis_tarihi: 'Arrival Date',
      teslim_tarihi: 'Delivery Date',
      siparis_bedeli: 'Order Value',
      fiyat_birimi: 'Price Unit',
      ek_maliyetler: 'Additional Costs',
      takip_kodu: 'Tracking Code',
      tedarikci_adi: 'Supplier Name',
      tedarikci_fatura_agirligi: 'Supplier Invoice Weight',
      depo_giris_tarihi: 'Warehouse Entry Date',
      depo_cikis_tarihi: 'Warehouse Exit Date',
      CompanyIds: 'Filtered Companies',
      siparis_tarihi: 'Order Date',
    },
  },
  menu: {
    groups: 'Groups',
    users: 'Users',
    modulManagement: 'Module Management',
  },
  company: {
    title: {
      users: 'Company Users',
    },
    roles: 'Company Roles',
  },
  titles: {
    orderFileTypeWarning: 'File Type Warning',
    user: '',
    line: '',
    output: '',
    tags: '',
    cart: '',
    number: '',
    discount: '',
    requests: '',
    creditCard: '',
    summary: '',
    choose: '',
    buy: '',
    warehouse: '',
    management: '',
    boughtItems: '',
    total: 'Total',
    offerResults: 'Offer Results',
    shipmentType: 'Shipment Type',
    from: 'From',
    to: 'To',
    load: 'Load',
    transferTime: 'Transfer Time',
    offer: 'Price',
    new: 'New',
    searchHistory: 'Search History',
    spotPriceRequests: 'Price Requests',
    offerDate: 'Offer Date',
    quickSearch: 'Quick Search',
    rateSupplier: 'Rate The Supplier',
    alreadyRateSupplier: 'The Supplier Already Rated',
    payments: 'Payments',
    paymentSummary: 'Payment Summary',
    details: 'Details',
    spotOfferMessages: 'Offer Messages',
    orderMessages: 'Order Messages',
    messageDate: 'Message Date',
    messageCompanyName: 'Company Name',
    referenceCode: 'Reference Code',
    loadDetail: 'Load Detail',
    attachments: 'Attachments',
    totalKilo: 'Total Weight',
    totalKiloShorten: 'Weight',
    totalVolume: 'Total Volume',
    totalVolumeShorten: 'Volume',
    theOffer: 'The Offer',
    expiryStatus: 'Expiry Status',
    activationSuccessful: 'Activation Successful',
    activationFailed: 'Activation Failed',
    contractDetails: 'Contract Details',
    totalVolumeWeight: '',
    blockUser: 'Block User',
    blockUserNameOf: 'Block {value}',
    blacklist: 'Blacklist',
    reason: 'Reason',
    blacklistedByUser: 'Blacklisted Users',
    blockedBy: 'Blocked By',
    orderOperations: 'Order Operations',
    subsupplier: 'Subsupplier',
    integrationService: 'Service',
    integrationPackaging: 'Subsupplier Packaging Type',
    integration: {
      title: 'Subsupplier Details',
      trackingNumber: 'Traking Number',
      serviceName: 'Service Name',
      netRateAmount: 'Net Rate Amount',
      label: 'Label',
      logisticsCompany: 'Subsupplier',
    },
    expenseOptions: 'Expense Options',
    dateAsNoun: 'Date',
    hourAsNoun: 'Hour',
    codeAsNoun: 'Code',
    locationAsNoun: 'Location',
    company: {
      roles: 'Company Roles',
    },

    dateTimeSettings: 'Date And Time Settings',

    invoiceIssued: 'Invoice Issued',
    invoiceNotIssued: 'Invoice Not Issued',

    accountingSettings: 'Accounting Settings',
    orderStatus: 'Order Status',

    orderType: 'Order Type',

    expressServiceType: 'Express Service Type',

    myExchangeRateList: 'My Exchange Rates',
    centralBankExchangeRateList: 'Central Bank Exchange Rates',

    priceDetails: 'Price Details',
    updateWeeklyAverages: 'Weekly Averages',
    preview: 'Preview',

    financialInformation: 'Financial Information',
  },
  title: {
    supportDetail: 'Support Detail',
    add: 'Add {value}',
    update: 'Edit {value}',
    photo: 'Photo',
    modulManagement: 'Module Management',
    stockManagement: 'Stock Management',
    orderProducts: 'Order Products',
    groups: 'Groups',
    users: 'Users',
    endorsement: 'Appreciation Thanks',
    moduleModalCreate: 'Add New Module',
    moduleModalUpdate: 'Update Module',
    groupModalCreate: 'Add New Group',
    groupModalUpdate: 'Update Group',
    userModalUpdate: 'Update User',
    userModalCreate: 'Add New User',
    logout: 'Logout',
    userDetails: 'User Detail',
    category: 'Category',
    status: 'Status',
    name: 'Name',
    categoryModalUpdate: 'Category Update',
    categoryCreate: 'Add New Category',
    makeAdmin: 'Make Admin',
    removeAdmin: 'Remove from Admin',
    addContent: 'Add Content',
    updateContent: 'Update Content',
    tableHeaderTrName: 'Turkish Name',
    tableHeaderEnName: 'English Name',
    orders: 'Orders',
    orderDetails: 'Order Details',
    products: 'Products',
    prohibitedWords: '',
    askHr: 'Ask HR',
    createSurvey: 'Add Survey',
    createBrand: 'Create Brand',
    surveyDetails: 'Survey Detail',
    brandDetails: '',
    tableHeaderIcon: 'Icon',
    tableHeaderName: 'Name',
    tableHeaderUsername: 'Username',
    tableHeaderProfilePic: 'Profile Picture',
    tableHeaderSignature: 'Profile Picture',
    tableHeaderLastName: 'Lastname',
    tableHeaderEmail: 'Email',
    tableHeaderKey: 'Module Key',
    tableHeaderCreateDate: 'Creation Date',
    tableHeaderEndDate: 'End Date',
    tableHeaderActions: 'Actions',
    tableHeaderSurveyQuestion: 'Survey Question',
    tableHeaderSurveyType: 'Survey Type',
    tableHeaderUserType: 'UserType',
    tableHeaderStatus: 'Status',
    tableHeaderSurveyAnswerCount: 'Number of Answers',
    tableHeaderModules: 'Module Name',
    tableHeaderWrite: 'Write',
    tableHeaderRead: 'Read',
    tableHeaderApprove: 'Publishing',
    deleteContentTitle: 'Delete Content',
    titleCustomFields: 'Custom Fields',
    customFieldCreate: 'Add Custom Field',
    customFieldEdit: 'Edit Custom Field',
    tableHeaderDisplayName: 'Name to Display',
    tableHeaderDataType: 'DataType',
    tableHeaderCustomFieldName: 'Custom Field Name',
    tableHeaderCustomFieldModuleName: 'Module Name It Belongs To',
    foodMenuModalCreate: 'Upload File',
    titleFoodMenu: 'Food Menu',
    titleReport: 'Reports',
    tableHeaderFoodMenuDate: 'Date Added to System',
    tableHeaderLikeCount: 'Like Count',
    tableHeaderFoodMenuName: 'Food Menu Name',
    tableHeaderFoodMenuCreator: 'Menu Creator',
    foodMenuModalDetail: 'Food Menu Detail',
    parentName: 'Parent Name',
    documentAssign: '',
    documentUpdate: '',
    ProfileInfo: 'Profile Information',
    categoryDetails: 'Detail',
    subCategoryCreate: 'Add SubCategory',
    tableHeaderFullNameSurvey: 'Survey Creator',
    tableHeaderFullNameAskHR: 'Question Creator',
    tableHeaderContentOwner: '',
    tableHeaderProhibitedWord: '',
    tableHeaderProhibitedWordCreator: '',
    forceUpdate: '',
    forceUpdateAdd: '',
    forceUpdateLabel: '',
    tableHeaderContentType: '',
    tableHeaderContentReason: '',
    tableHeaderSuspensefulContent: '',
    stories: 'Stories',
    tableHeaderTitle: 'Title',
    tableHeaderThumbNail: 'Image',
    addStory: 'Add Story',
    brandName: 'Brand',
    notificationType: 'Notification Target',
    Creator: 'Creator',
    storyData: 'Story Content',
    storyLinkType: 'Story Link Type',
    notificationRedirectPage: 'Page to Redirect',
    contentPageHeaderAdd: 'Add',
    contentPageHeaderUpdate: 'Update',
    moduleDescription: 'Module Description',
    blockedUsers: '',
    advertModalUpdate: 'Update Advert',
    advertModalCreate: 'Add Advert',
    endorsementTopicModalCreate: 'Add Appreciation & Thanks',
    endorsementTopicModalUpdate: 'Update Appreciation & Thanks',
    tableHeaderEndorsementTopicImage: 'Image',
    tableHeaderEndorsementTopicDescription: 'Description',
    tableHeaderEndorsementTopicSortOrder: 'Sort',
    tableHeaderEndorsementTopicType: 'Key Type',
    tableHeaderEndorsementTopicHeader: 'Title',
    brands: 'Brands',
    brandDetail: 'Brand Detail',
    categories: '',
    categoryDetail: 'Category Detail',
    deleteCategory: 'Delete Category',
    newCategory: '',
    importTitle: 'Import',
    addTag: 'Add Tag',
    priceChange: 'Price Change',
    exportTitle: 'Export',
    messages: 'Messages',
    bulkAddTag: 'Bulk Add Tag',
    bulkUpdateProduct: 'Bulk Price Change',
    importFileInstructions: 'File Upload Instructions',
    stockTransfer: 'Stock Transfer Between Warehouses',
    productInformation: 'Product Information',
    stockInformation: 'Stock Status',
    companyInformationDetail: 'Company Details',
  },
  description: {
    404: '404 - Page Not Found',
    updateExpiryDateSuccess: 'Expiry date has been updated.',
    smsVerificationCheckboxLabel: 'I approve 2FA Protection',
    updateExpiryDateError: 'Expiry date could not be updated.',
    packageDetailsUpdated: 'Package details have been updated.',
    bankInformationAddSuccess: 'Bank information has been added.',
    bankInformationEditSuccess: 'Bank information has been updated.',
    bankInformationDeleteSuccess: 'Bank information has been deleted.',
    makeSurePayment:
      'Please ensure that the payment has been received before providing dates such as loading, departure, arrival, and delivery',
    orderCanceled: 'Order has been canceled.',
    pleaseRegister: '',
    marketplaceNotSelected: 'Please select the marketplace.',
    titleInformation: 'Information',
    logoutInformationText: 'Your session has expired. You have to login again.',
    passwordLength: {
      min: 'Your password must be at least six characters long.',
      max: 'Your password can be up to twenty-four characters long.',
    },
    passwordRequirements: {
      uppercase: 'Your password must contain at least one uppercase letter.',
      lowercase: 'Your password must contain at least one lowercase letter.',
      digit: 'Your password must contain at least one digit.',
      specialChar: 'Your password must contain at least one special character.',
    },
    addCompanyInformationText:
      'You need to fill in your company information before using the application.',
    signatureInformationText:
      'You need to upload your signature to give prices to customers and create orders within the app.',
    forgotPassword: 'Forgot My Password',
    verifyResetToken: 'Change My Password',
    invoiceSuccess: 'Invoice has been approved successfully',
    invoiceError: 'Invoice could not be approved, please try again.',
    orderSuccess: 'Order has been approved successfully',
    orderError: 'Order could not be approved, please try again.',
    invoiceLoading: 'Invoice is being approved, please wait.',
    orderLoading: 'Order is being approved, please wait.',
    noList: 'List is empty.',
    deleteModalTitle: 'Deletion Action',
    statusToActiveModalTitle: 'Status To Active',
    statusToInactiveModalTitle: 'Status To Inactive',
    contentDenyTitle: '',
    contentApproveTitle: '',
    deleteModalDescription:
      'Are you sure you want to delete the selected data?',
    StatusToActiveDescription: 'Are you sure you want to make status active?',
    StatusToInactiveDescription:
      'Are you sure you want to make status inactive?',
    deletedCategory: '',
    contentModalDescription: '',
    invalidKey:
      'Please only use English letters and the underscore sign ( _ ) in the key field.',
    logout: 'Are you sure you want to log out?',
    groupLengthMessage: 'Note: You can add up to 4 lines.',
    tableLoadingError:
      'An error occurred while loading the table, please try again.',
    makeAdminDescription: 'Are you sure you want to make this person admin?',
    removeAdminDescription:
      'Are you sure you want to remove this person from admin?',
    authorizationUserText1: "Define the user's privileges, then",
    authorizationUserText2: 'click the following button.',
    deleteContent: 'Are you sure you want to delete the selected content?',
    invalidDatePick: 'Please enter a valid date range.',
    invalidCategoryMarketPlace: '',
    invalidUserSelection: '',
    excelDataError: 'Please select report and data to export.',
    excelColumnError: 'Please select report and data with column to export.',
    invalidMenuSelect: 'Please select at least one menu.',
    selectModule: 'Please select a module',
    fileSizeError:
      'Please select a file that complies with the file size limit.',
    notificationTargetError:
      'Please select at least one target before sending notification.',
    unsupportedMediaType:
      'Unsupported file type, please select a different file type.',
    surveyAnswerBoundReached: 'Reply adding limit reached.',
    surveyEmptyAnswer: 'Cannot add empty answer',
    wrongDateInput: 'Start date cannot be later than End date.',
    selectPage: 'Please Select a Page',
    selectInnerPage: 'Please Select an Inner Page',
    notificationSendError: 'Notification could not be sent, please try again.',
    storyPhotoError: 'Please upload at least one photo.',
    tagCountReached:
      'You can add a maximum of 15 tags, please refresh the page to reset.',
    propertyEmptySicil:
      'Empty registration number cannot be added, please add registration number.',
    propertyEmptyPublishDate:
      'The information in the custom fields section is required, please fill in completely',
    contentPhotoUpload: 'Please fill in the required photo field.',
    groupRowDelete:
      'You cannot delete this row. At least one group line must be entered.',
    emptyCategorySelect:
      'Category selection is required, please select at least one category.',
    draftNotificationInfo:
      'Publishing as draft, notification will not be sent.',
    contentFileUpload: 'Please upload at least one file.',
    contentFileUploadOnlyPdf: 'Please upload at least one file only pdf .',
    errorOnCreate: 'Please fill the mandatory inputs and try again.',
    errorOnCreateContent: 'Please post according to the content rules.',
    communityModule:
      'This module contents can be accessed by the entire community.',
    invalidFileType: 'Please select a valid file type to publish the content.',
    authorizationError:
      'There is an authorization problem, please login again.',
    serverError: 'There is a problem with the servers, please try again later.',
    deleteCategory: 'Please choose new category for products',
    noOrderId: 'Order number not detected, you are being redirected to orders.',
    bulkUpdateProduct:
      'By selecting Marketplace and percentage change, you can update the prices of the selected products in the selected markets.',
    bulkAddTag:
      'Select the marketplaces you want to add tags to and enter the tags you want to add.',
    addStock: 'You must add stock before putting the product on sale.',
    addStockDesc:
      "Enter the number of stocks and the cost of the product in 'TL'.",
    stockTransferDesc:
      'Enter from which warehouse you will transfer and the number of stocks to transfer.',
    saveOrderAsDraft:
      'The order will be saved as a draft, check the box `I want it to be sent to the supplier` to send it as an order to the supplier.',
    orderFileTypeWarningDesc:
      'You need to upload the mandatory file type or types for your order to go to the supplier as an order.',
    planInfoDesription:
      'There was an error in the scheduling process, please try again later.',
  },
  messages: {
    updateExpiryDate: 'Expiry date has been updated.',
    updatePriceExcel: 'Price update has been successfully completed.',
    updateRegionExcel: 'Region update has been successfully completed.',
    updateTransportTimeExcel:
      'Transport time update has been successfully completed.',
    updateIntegrationOrders: 'Integration orders have been updated.',
    paymentSuccessful: 'Payment has been successfully completed.',
    paymentFailed: 'Payment could not be completed.',
    paymentRedirect: 'You will be redirected to the payment page.',
    updateListNameSuccess: 'List name has been updated.',
    updateCompanyNotificationsSuccessful:
      "The company's notification settings has been updated successfully.",
    updateCompanyNotificationsError:
      'Company notifications could not be updated.',
    updateCompanyNotificationLanguageSuccessful:
      'Company notification language has been updated.',
    updateCompanyNotificationLanguageError:
      'Company notification language could not be updated.',
    updateHazmatPricesSuccessfully:
      'Your surcharge information has been successfully updated.',
    paymentApproved: 'Payment has been approved.',
    dateSelectionOrder:
      'You need to select the dates in sequence. The order for entering dates is as follows:',
    dateOrder: {
      estimatedDeliveryDate: 'Estimated Delivery Date',
      loadingDate: 'Loading Date',
      departureDate: 'Departure Date',
      arrivalDate: 'Arrival Date',
      deliveryDate: 'Delivery Date',
    },
    shipmentBoxUpdateSuccess: 'Shipment box has been updated.',
    shipmentBoxUpdateError: 'Shipment box could not be updated.',
    complaintSuccess: 'Complaint has been sent successfully.',
    complaintError: 'Complaint could not be sent, please try again.',
    contractSignSuccess: 'Contract signed successfully.',
    contractSignError: 'Contract could not be signed, please try again.',
    contractNotSigned: 'In order to continue, you must sign the contract.',
    deleteReceiptSuccess: 'Your receipt has been deleted successfully.',
    deleteReceiptError: 'Your receipt could not be deleted, please try again.',
    updateReceiptSuccess: 'Your receipt has been updated successfully.',
    updateReceiptError: 'Your receipt could not be updated, please try again.',
    addReceiptSuccess: 'Your receipt has been added successfully.',
    fileAddSuccess: 'The file was successfully added.',
    fileDeleteSuccess: 'The file was successfully deleted.',
    addReceiptError: 'Your receipt could not be added, please try again.',
    addAddressSuccess: 'Your address has been added successfully.',
    addAddressError: 'Your address could not be added, please try again.',
    editAddressSuccess: 'Your address has been updated successfully.',
    editAddressError: 'Your address could not be updated, please try again.',
    deleteAddressSuccess: 'Your address has been removed successfully.',
    deleteAddressError: 'Your address could not be removed, please try again.',
    createOfferFormInfoTextTitle: 'The Name of Smooth and Fast Shipments: ',
    integrationText:
      'With the marketplace integration within the Algönder system, you can instantly find the best shipping prices for your sales on Etsy, Amazon, Ebay, Shopify, Wallmart and many other marketplaces in the fastest way.',
    etsyText:
      "The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
    createOfferFormInfoTextDescription:
      'Reliable and Fast Deliveries: AlGönder\nAlGönder is the new destination for companies to deliver their domestic and international packages with a fast, reliable, and personalized experience.',
    profileUpdateSuccess: 'Your profile has been updated successfully.',
    profileUpdateError: 'Your profile could not be updated, please try again.',
    ticketCreated: 'Your ticket has been created successfully',
    ticketCreatedError: 'Your ticket could not be created, please try again.',
    ticketDeleteSuccess: 'Your ticket has been deleted successfully',
    ticketDeleteError: 'Your ticket could not be deleted, please try again.',
    notifictionUpdateSuccess: 'Notification updated successfully.',
    notifictionUpdateError: 'Notification update failed.',
    completePackageOrder: '',
    completePackageOrderError: '',
    defaultSuccess: '',
    forgotPasswordSuccess: 'Reset password URL has been sent successfully.',
    successfulUpdate: 'Your {value} has been updated successfully.',
    successfulAdd: 'Your {value} has been added successfully.',
    successfulDelete: 'Your {value} has been removed succesfully.',
    successfulArchive: 'Your {value} has been archived succesfully.',
    successfulUnarchive: 'Your {value} has been unarchived succesfully.',
    successfulSave: 'Your {value} has been saved succesfully.',
    successfulTest: 'You have tested it succesfully.',
    errorTest: 'Your testing is failed. Please check your data.',
    alreadyBought: '',
    noData: 'No data here.',
    notFoundSearch: 'We have not found anything about your search.',
    listEmpty: 'The list seems like empty.',
    noOffer:
      'We have not found anything by your search criterias but you can demand an offer from supplier.',
    noOfferSpotShipping:
      'The suppliers did not give any offer for your shipping.',
    demandPriceSuccessfull: 'Your price demand has been sent successfully.',
    approvedSuccessfully: 'You have approved it successfully.',
    areYouSureToApprove: 'Are you sure to approve this offer?',
    noSearch:
      'Your search history seems like empty. You can create a new search for your shipping.',
    rateSupplier: "You can rate your supplier's service",
    ratedSuccessfully: 'You have rated the supplier successfully.',
    noRequestSupplier: 'There is no request yet.',
    unexpectedError: 'There is an unexpected error.',
    noOrders: 'Your order list seems like empty.',
    waitingForPayment: 'Waiting For The Payment',
    paid: 'Paid',
    noPayments: 'Your payment list seems like empty.',
    verificationCodeSent:
      'A verification code sms was sent to your *********{phone} number.',
    needsToSignedByCustomer: 'Waiting for payment',
    needsApprovementByCustomer: 'Waiting for the customer to approve.',
    pleaseDoNotCloseThePage: 'Please do not close or refresh the window.',
    loadingText: 'Operation is being processed',
    shouldRegister:
      'You need to register to view all search details and prices.',
    successfulAddExpress: 'Your list has been added successfully.',
    updateActiveExpress: "Your list's status has been changed successfully.",
    updateFSC: 'FSC rate has been updated successfully.',
    changeFileSuccessful: 'Your Excel file has been changed successfully.',
    changeTheExcelFile: 'Are you sure to upload your Excel file?',
    deleteCompanyUser:
      'You have removed the user that has authority successfully.',
    activationSuccessful: 'Your account has been activated successfully.',
    activationFailed: 'The activation of your account failed.',
    noTransshipment: 'needs no any transshipment.',
    withTransshipment: 'needs 3 transshipments.',
    noUser: 'There is no any registered user yet.',
    updateUserStatusSuccessful:
      "The user's status has been updated successfully.",
    updateUserStatus: "Are you sure to update this user's status?",
    updateCompanyStatus: "Are you sure to update this company's status?",
    noUserInCompany: 'There is no any registered user in this company yet.',
    companyInPending:
      'Your company account is under approval. You will be notified by e-mail when your company is approved.',
    waitingForApproval: '',
    noRemainingRefreshCount: 'You have exceeded the refresh limit.',
    extraPayment: 'Extra Payment',
    waitingForPaymentApproval: 'Waiting For Payment Approval',
    approvedPayments: 'Approved Payments',
    closeTicket: 'Are you sure to close this support ticket?',
    addVolumeWarning:
      "Your first volume values and package's volume values seems like different. Are you sure to save?",
    updateBoxesSuccessfully: "Your boxes' data have been updated.",
    addBlacklistSuccessfully:
      'You have added the user to your blacklist sucessfully.',
    addPlanMessageSuccessfully: 'Selected data are marked.',
    removeBlacklistSuccessfully:
      'You have removed the user from blacklist sucessfully.',
    sureToBlockUser: 'Are you sure to block this user?',
    sureToApprovePayment: 'Are you sure to approve this payment?',
    noBlacklistedUser: 'There is no blocked user.',
    noWaitingForApprovalPayment: 'There are no payments waiting for approval.',
    noApprovedPayment: 'There are no approved payments.',
    banUserSuccessfully: 'You have ban this user successfully.',
    unbanUserSuccessfully: "You have removed this user's ban sucessfully.",
    rejectPaymentApprovement: 'Are you sure to take payment back?',
    paymentRejected: '',
    sendSupplier: 'I want to send the supplier.',
    noDepartureDate: '',
    createdShipmentSuccessfully: 'Your shipment has been created succesfully.',
    successfullLogin: 'You have authorized the system successfully.',
    failedLogin: 'Your authorization has been failed.',
    maximumKg:
      'The maximum gross weight per by cap of this price entry is {maximumKg} kg. Therefore you cannot create an order.',
    mailHasBeenSentSuccessfully: 'Your mail has been sent successfully.',
    noArrivalDate:
      'Your Depature Date is empty. Please provide an departure date for the shipment.',

    dateTimeOfWarehouse:
      'Entrance and Exit Date of The Warehouse has been updated.',
    successfulUpdateGeneral: 'You have updated successfully.',

    paymentProcessing: 'Your payment is processing.',

    multipleEmailInformation:
      'If you want to add multiple email addresses, you can separate them with semicolons.',

    successfulUpdateUserRole: 'The role of user has been updated successfully.',

    oversizeMessage:
      'Since the package dimensions were not provided, an oversize check could not be performed. The price in your order details may vary depending on the package details.',
    longDistanceMessage:
      'Since the postal code was not provided, a remote area check could not be performed. The price in your order details may vary depending on the entered postal code.',
    noPackageDetails:
      'You cannot send the order to the supplier without entering the package details. Please check and ensure all details are provided.',
    addedDevAPISuccessfully:
      'The API configuration has been successfully added.',

    successfulAddGeneral: 'You have added successfully.',

    succesfulUpdateWeeklyAverages:
      'Your weekly average values has been updated successfully.',
    listedAlready: 'You had already listed this request as {price}.',

    requiredFields: 'Data you need to provide for row {index}: {fields}',
    requiredFieldsWarning:
      'Before saving the order, you need to fill in the following fields.',

    noGoodsTypeWarning:
      'You need to fill in the Shipment Description field. Should the Item Type value of the first package listed in the package details be automatically filled in as the Shipment Description?',
    envelopeWeightWarning:
      'Since your total weight is 0.5 kg, you cannot choose the envelope packaging type.',
  },
  navigationDrawerMenu: {
    subscriptionManagement: 'Subscription Management',
    transferEntry: 'EX. Freight Difference',
    adminPackageManagment: 'Package Management',
    home: 'Home',
    priceChartEntry: 'Express Price Entry',
    partialTransportation: 'Partial Transportation',
    outOfAreaEntry: 'Out of Area Entry',
    periodicCostEntry: 'Express Freight Difference Entry',
    quickSearch: 'Quick Price Search',
    spotOffersSupplier: 'Offer Price Requests',
    offers: 'Offers',
    spotOffersCustomer: 'Offers',
    searchHistory: 'My Search History',
    orders: 'Orders',
    ordersAdmin: 'Orders',
    marketplace: 'Marketplaces',
    portalManagement: 'Portal Management',
    plannerManagement: 'Planner Management',
    integrationManagement: 'Integration Management',
    publicApi: 'Dev API',
    messages: 'Messages',
    payments: 'Payments',
    reports: 'Reports',
    company: 'Company Information',
    address: 'My Addresses',
    profile: 'Profile',
    support: 'Help',
    userGuide: 'User Guide',
    faq: 'FAQ',
    settings: 'Settings',
    help: 'Help',
    addCompany: 'Add Company',
    devSpecial: 'Developer Menu',
    logging: 'Logging',
    companyManagement: 'Company Management',
    users: 'Users',
    admin: 'Admin',
    companies: 'Companies',
    timeOut: 'Time Out Settings',
    marketplaceOrders: 'Integration Orders',
    blacklist: 'Blacklist',
    approvePayment: 'Approve Payment',
    warehouse: 'Warehouse Management',
    dictionary: 'Dictionary',
    pricing: 'Price Entry',
    management: 'Management',
    other: 'Other',
    library: 'Library',
    additionalCosts: 'Ek Maliyetler',
    accounting: 'Accounting Management',
    listOffers: 'List Offers',
    currencyManagement: 'Exchange Rate Management',
    dynamicReports: 'Dynamic Reports',
    fixedReports: 'Fixed Reports',
    graphicalReports: 'Graphical Reports',
  },
  hazmatCodes: {
    11: 'Parital - Airway',
    15: 'Parital - Railway',
    25: 'Complete - Railway',
    33: 'FCL - Seaway',
    43: 'LCL - Seaway',
    52: 'FTL - Roadway',
    62: 'LTL - Roadway',
  },
  adminFilters: {
    supplier: 'Supplier Summary',
    supplierDetail: 'Supplier Detail Summary',
    customer: 'Customer Summary',
    customerDetail: 'Customer Detail Summary',
    carriageType: 'Carriage Type Summary',
  },
  adminHeaders: {
    SupplierName: 'Supplier Name',
    SumWeight: 'Sum of Weight',
    SumPrice: 'Sum of Price',
    SumCapPieces: 'Sum of Cap/Pieces',
    ShipmentType: 'Shipment Type',
    PosPayment: 'POS Payment',
    PortalRate: 'Portal Rate',
    PoolRate: 'Pool Rate',
    OrderCount: 'Order Count',
    MembershipType: 'Membership Type',
    ExportImportType: 'Export/Import Type',
    CustomerCount: 'Customer Count',
    Commission: 'Commission',
    CustomerName: 'Customer Name',
    CustomerType: 'Customer Type',
    SearchCount: 'Search Count',
    SupplierCount: 'Supplier Count',
  },

  packageModal: {
    airlineCommission: 'Air Freight Commission',
    seawayCommission: 'Sea Freight Commission',
    roadCommission: 'Highway Freight Commission',
    railwayCommission: 'Rail Freight Commission',
    expressCommission: 'Express Freight Commission',
    domesticCommission: 'Domestic Freight Commission',
    portalCommission: 'Portal Commission',
    integrationCommission: 'Integration Commission',
    portalCommision: 'Portal Commission',
    shipmentCommision: 'Shipment Commission',
  },
};
